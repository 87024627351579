import React, { useEffect, useState } from "react";

function Main({ currentVideoId, onSearch }) {
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(null);
  const [showTagForm, setShowTagForm] = useState(false);
  const [newTagName, setNewTagName] = useState("");

  useEffect(() => {
    const fetchTags = async () => {
      try {
        console.log(`Fetching tags for video ID: ${currentVideoId}`); // Debug info
        const response = await fetch(
          `http://https://cutecambabes.com/api/tags/video/${currentVideoId}`
        ); // Adjust the URL as needed
        if (!response.ok) {
          throw new Error(`Failed to fetch tags: ${response.statusText}`);
        }
        const data = await response.json();
        console.log("Tags fetched:", data); // Debug info
        setTags(data);
      } catch (error) {
        console.error("Error fetching tags:", error);
        setError(error.message);
      }
    };

    fetchTags();
  }, [currentVideoId]);

  const handleShowTagForm = () => {
    setShowTagForm(!showTagForm);
  };

  const handleAddTag = async () => {
    if (!newTagName.trim()) return;

    try {
      // First, check if the tag already exists
      let tagResponse = await fetch(
        `http://https://cutecambabes.com/api/tags`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!tagResponse.ok) {
        throw new Error("Failed to fetch existing tags");
      }

      const existingTags = await tagResponse.json();
      let existingTag = existingTags.find(
        (tag) => tag.name.toLowerCase() === newTagName.toLowerCase()
      );

      let createdTag;

      if (existingTag) {
        // If the tag exists, use the existing tag
        createdTag = existingTag;
      } else {
        // If the tag does not exist, create it
        const createResponse = await fetch(
          "http://https://cutecambabes.com/api/tags",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ name: newTagName }),
          }
        );

        if (!createResponse.ok) {
          throw new Error("Failed to create tag");
        }

        createdTag = await createResponse.json();
      }

      // Now associate the tag (whether newly created or existing) with the video
      const associateResponse = await fetch(
        `http://https://cutecambabes.com/api/tags/video/${currentVideoId}/tags`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tagId: createdTag.id }),
        }
      );

      if (!associateResponse.ok) {
        throw new Error("Failed to associate tag with video");
      }

      setTags([...tags, createdTag]);
      setNewTagName("");
      setShowTagForm(false);
    } catch (error) {
      console.error("Error creating or assigning tag:", error);
    }
  };

  return (
    <main>
      <section className="video-tags">
        {error ? (
          <p>Error: {error}</p>
        ) : tags.length > 0 ? (
          tags.map((tag) => (
            <span key={tag.id} className="tag-badge">
              {tag.name}
            </span>
          ))
        ) : (
          <p>No tags available</p>
        )}
      </section>

      <button onClick={handleShowTagForm} style={{ marginRight: "20px" }}>
        Add tags to this video:
      </button>

      {showTagForm && (
        <div>
          <input
            type="text"
            value={newTagName}
            onChange={(e) => setNewTagName(e.target.value)}
            placeholder="Enter new tag name"
          />
          <button onClick={handleAddTag}>Submit</button>
        </div>
      )}

      <div className="topcard">
        <h1 id="portfolio">Ad Location</h1>
      </div>

      <div className="topcontain">
        <img
          className="toppiece"
          src="./assets/images/Flir-macbook.jpg"
          alt="A warm Mackbook laptop after hours of usage."
        />
      </div>
    </main>
  );
}

export default Main;
