import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import YouTube from "react-youtube";

function VideoDetail() {
  const { id } = useParams(); // Get the video ID from the URL
  const [video, setVideo] = useState(null);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const response = await fetch(
          `http://https://cutecambabes.com/api/videos/${id}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setVideo(data.video);
        setTags(data.tags); // Assuming your API returns the tags as well
      } catch (error) {
        console.error("Error fetching video:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVideo();
  }, [id]);

  const opts = {
    height: "480",
    width: "854",
    playerVars: {
      autoplay: 1,
    },
  };

  if (loading) return <p>Loading video...</p>;
  if (error) return <p>Error loading video: {error.message}</p>;

  return (
    <div>
      <h1>{video.title}</h1>
      {video.platform === "youtube" ? (
        <YouTube videoId={video.platform_id} opts={opts} />
      ) : video.platform === "dailymotion" ? (
        <div
          style={{
            position: "relative",
            paddingBottom: "56.25%",
            height: 0,
            overflow: "hidden",
          }}
        >
          <iframe
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              left: 0,
              top: 0,
              overflow: "hidden",
            }}
            frameBorder="0"
            type="text/html"
            src={`https://www.dailymotion.com/embed/video/${video.platform_id}?autoplay=1`}
            width="100%"
            height="100%"
            allowFullScreen
            title="Dailymotion Video Player"
            allow="autoplay; web-share"
          ></iframe>
        </div>
      ) : video.platform === "SPB" ? (
        <div
          style={{
            position: "relative",
            paddingBottom: "56.25%",
            height: 0,
            overflow: "hidden",
          }}
        >
          <iframe
            style={{
              width: "100%",
              height: "110%",
              position: "absolute",
              left: 0,
              top: 0,
              overflow: "hidden",
            }}
            frameBorder="0"
            type="text/html"
            src={`https://spankbang.com/${video.platform_id}/embed/?autoplay=0`}
            width="100%"
            height="100%"
            allowFullScreen
            title="SPB Video Player"
            allow="autoplay; web-share"
          ></iframe>
        </div>
      ) : null}

      {/* Example: passing videoId to Main if needed */}
      {/* <Main videoId={id} /> */}

      {/* Display Tags */}
      <div className="tags-container">
        {tags.map((tag) => (
          <span key={tag.id} className="tag">
            {tag.name}
          </span>
        ))}
      </div>
    </div>
  );
}

export default VideoDetail;
