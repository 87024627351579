import React from 'react';

function Footer() {
  return (
    <footer>
      <section className="contact">
        <div>
          <h3 className="h4new">Contact</h3>
          <div className="finish">Footer</div>
        </div>
        {/* Removing the unnecessary empty div */}
      </section>
    </footer>
  );
}

export default Footer;
